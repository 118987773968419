import React from "react";
import Header from "../../../components/Landers/Header";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/Brands";
import GetStarted from "../../../components/Landers/GetStarted";
import InfoCreditCheck from "../../../components/Landers/InfoCreditCheck";
import SEO from "../../../components/Landers/SEO";
import SectionTemplate from "../../../components/Landers/SectionTemplate";
import Testimonials from "../../../components/Landers/Testimonials";
import WhatsUnique from "../../../components/Landers/WhatsUnique";
import styles from "../../../components/Landers/landers.module.scss";
import Footer from "../../../components/Landers/Footer";
import DataInfo from "../../../components/Data";
import Steps from "../../../components/Landers/Steps";

const testimonials = [
  {
    name: "Rupasi M",
    company: "Datarynx Pvt. Ltd.",
    p: `SpringVerify is our go-to app for all our background checks.
			I'm in love with its flexibility, time-bound closures,
			and a support team that is always ready to answer our queries 24x7.`,
  },
  {
    name: "Ravindra M",
    company: "Rupeezen",
    p: `I love the simplicity and clean UI of SpringVerify. As the checks are digitized, there's no need for human intervention.
			This has helped us to quicken the onboarding time for our fresh hires.`,
  },
  {
    name: "Maryam J",
    company: "Resolvit Management and Technology Consulting India Pvt. Ltd.",
    p: `SpringVerify is very simple, reliable, and straightforward. The UX is awesome, checks are fast and most importantly all
			my queries are answered promptly without me having to wait for long.`,
  },
];

export default () => (
  <div className={styles.solutions}>
    <SEO
      title="Digital DIN Verifications Service | SpringVerify India"
      desc="Digital DIN Verifications Service | SpringVerify India"
      url="https://in.springverify.com/screenings/din-verification/"
      img_url="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/SV-IN+(DIN+Verification).jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        className={styles.CheckBanner}
        h1={`Comprehensive DIN \nVerifications for \nyour company`}
        p={`Gain thorough insights on your candidate's past and present \ndirectorship to make better hiring decisions with SpringVerify.`}
        img="https://assets-sv-in.gumlet.io/LP/din-verify/din-banner-illustration.png"
      />
    </div>
    <Brands />
    <section>
      <SectionTemplate
        img="din-verify/impo-din.svg"
        h2={`Importance of DIN \nVerifications`}
        p={`Directorship checks are vital for protecting your business from potential issues \nthat can damage your brand and reputation. 
        Here’s how DIN verification helps \nemployers to make better hiring decisions`}
        ul={[
          "Complete factual information is screened to avoid wrong hires",
          "Avoid conflicts that may arise because of the candidate’s past shareholdings",
          "Involvement of the candidates with companies that went bankrupt",
        ]}
      />
    </section>
    <InfoCreditCheck data={DataInfo.InfoCreditCheck} />
    <Steps data={DataInfo.StepsWrap} />
    <WhatsUnique
      path="Employment"
      info={[
        "Easy onboarding",
        "Safe and secure",
        "Transparent pricing",
        "Workflow automation",
        "Tailored solutions based on your requirements",
        "Powered by AI and Blockchain",
        "Remote-friendly and contactless verifications",
        "Compliant with ISO 27001, PCI, and more",
      ]}
    />
    <Testimonials testimonials={testimonials} path="Employment" />
    <GetStarted
      img="getStarted/din-getStarted.svg"
      h3={`Get started with DIN \nVerifications now`}
      p="Hire exceptional leaders for your organization with reliable DIN Verifications."
    />
    <Footer />
  </div>
);
