import React from "react";
import Styles from "./Steps.module.scss";

const SVCreditChecks = (props) => {
  return (
    <section>
      {props.data.map((data, index) => {
        return (
          <div className={Styles.SVCreditChecksWrap} key={index}>
            <div className={Styles.SVCredCheckHeader}>
              <p>{data.subHeader}</p>
              <h2>{data.header}</h2>
            </div>
            <div className={Styles.SVCredCheckSection}>
              {data.Illustration ? (
                <div className={Styles.SVCCSectionLeft}>
                  {data.Illustration.map((Image, i) => (
                    <img src={Image.link} key={i} />
                  ))}
                </div>
              ) : null}
              {data.Steps ? (
                <div className={Styles.SVCCSectionRight}>
                  {data.Steps.map((Step, i) => (
                    <div className={Styles.SVCCInner} key={i}>
                      <div className={Styles.SVCCInnerLeft}>
                        <img src={Step.path} />
                      </div>
                      <div className={Styles.SVCCInnerRight}>
                        <h4>{Step.title}</h4>
                        <p>{Step.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default SVCreditChecks;
